import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "../../components";
import { actions, selectors } from "../../store";
import { quickSearchTour } from "../../tours";
import { Input } from "./_basic-search.input";

export const SearchInput = ({ searchTerm, disabled }) => {
  const dispatch = useDispatch();
  const hasViewedTour = useSelector(selectors.user.getQuickSearchTourViewed);
  const tourIsRunning = useSelector(selectors.tour.getRun);
  const isHidden = hasViewedTour || tourIsRunning;

  const clearTour = () => {
    dispatch(actions.user.setTourViewed({ tourViewed: "quickSearch" }));
  };

  const runTour = () => {
    dispatch(actions.tour.run({ steps: quickSearchTour, onEnd: clearTour }));
  };

  const InputProps = {
    "aria-label": "search term input",
    dataTestId: "searchInputBox",
    ariaDescriptionId: "ariaDescriptionBox",
    placeholder: "Search for grantor/grantee, subdivision, doc type, or doc#",
    ariaDescription:
      "Use this field to search for the name of a Grantor/Grantee or by subdivision, document type or document number.",
  };

  const tooltipProps = {
    isHidden,
    onClick: runTour,
    onClear: clearTour,
    headerText: "Not sure where to start?",
    buttonText: "Take the Tour",
    bodyText:
      "Take a quick tour through the app and you can become a search expert in no time.",
  };

  return (
    <Tooltip
      visible={!isHidden}
      placement="bottomLeft"
      overlay={<Popup {...tooltipProps} />}
    >
      <Input
        {...InputProps}
        value={searchTerm.value}
        onChange={searchTerm.update}
        onKeyDown={searchTerm.handleEnter}
        disabled={disabled}
        dataTourId={InputProps.dataTestId}
      />
    </Tooltip>
  );
};
