import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router";
import { Link } from "../../../components";
import { withWorkspaceId } from "../../../hoc";
import { actions, selectors } from "../../../store";
import AdvancedSearch from "../../../units/search.advanced";
import BasicSearch from "../../../units/search.basic";
import SearchMasthead from "./search-masthead";
import SearchHistory from "./SearchHistory";

export const Search = ({ workspaceID }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { workspaces, configuration } = selectors;
  const shouldLimitSearch = useSelector(configuration.isLimitedSearchEnabled);
  const deptCode = useSelector(workspaces.getSelectedDepartment);
  const shouldFetchDeptDates = useSelector(
    workspaces.determineDeptHasEmptyDate
  );

  const removeFetchedDocuments = (workspaceID) =>
    dispatch(actions.documents.removeFetchedDocuments({ workspaceID }));

  const fetchDepartmentDateRanges = (nextDeptCode) =>
    dispatch(
      actions.search.fetchDepartmentDateRanges({
        shouldFetch: shouldFetchDeptDates,
        department: nextDeptCode || deptCode,
      })
    );

  useEffect(() => {
    fetchDepartmentDateRanges();
    removeFetchedDocuments(workspaceID);
  }, []);

  if (shouldLimitSearch && location.pathname === "/") {
    return (
      <Redirect to={{ pathname: "/search/advanced", state: { workspaceID } }} />
    );
  }

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <SearchMasthead />
      <ul className="search__types">
        <li className="search__type">
          <Link
            disabled={shouldLimitSearch}
            exact
            to="/"
            className="search__select-type"
            title={
              shouldLimitSearch ? "Quick Search (disabled)" : "Quick Search"
            }
          >
            Quick Search
          </Link>
        </li>
        <li className="search__type" data-tourid="advancedSearchLink">
          <Link
            exact
            title="Advanced Search"
            to="/search/advanced"
            className="search__select-type"
          >
            Advanced Search
          </Link>
        </li>
      </ul>
      <Switch>
        <Route exact path="/" render={() => <BasicSearch />} />
        <Route
          path="/search/advanced"
          render={() => (
            <AdvancedSearch
              fetchDepartmentDateRanges={fetchDepartmentDateRanges}
            />
          )}
        />
      </Switch>
      {!shouldLimitSearch && <SearchHistory />}
    </>
  );
};

export default withWorkspaceId(Search);
