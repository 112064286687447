import classnames from "classnames";
import { useState } from "react";

const PasswordInput = (props) => {
  const {
    showPasswordOption = false,
    className = "",
    onChange,
    value,
    placeholder,
    required,
    id,
  } = props;

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const showPassword = showPasswordOption && isPasswordShown;
  const togglePassword = () => setIsPasswordShown((v) => !v);

  const ShowButton = (
    <div className="password-input__show-button">
      <button
        onClick={togglePassword}
        type="button"
        aria-label={showPassword ? "hide password" : "show password"}
      >
        {showPassword ? "HIDE" : "SHOW"}
      </button>
    </div>
  );

  return (
    <div className={classnames(className, "password-input")}>
      <input
        type={showPassword ? "text" : "password"}
        className={classnames("password-input__input", {
          "password-input__input--mask": showPasswordOption,
        })}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        required={required}
        data-testid="loginPassword"
        id={id || "password"}
      />
      {showPasswordOption && ShowButton}
    </div>
  );
};

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
