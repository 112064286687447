import { A11yAnnouncement, ReturnButton } from "../../../components";
import { useLocation } from "react-router-dom";
import View from "./View";
import { parse } from "query-string";

const SignIn = () => {
  const { search } = useLocation();
  const query = parse(search);
  const isVerified = query.verified === "true";

  return (
    <div className="sign-in">
      <A11yAnnouncement>Navigated to sign in page</A11yAnnouncement>
      <ReturnButton />
      <View verified={isVerified} />
    </div>
  );
};

SignIn.displayName = "SignIn";

export default SignIn;
