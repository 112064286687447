import { css } from "@emotion/react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { A11yAnnouncement } from "../../../components";
import { useInterval } from "../../../hooks";
import { selectors } from "../../../store";
import { Container } from "./_container";
import { Header } from "./_header";
import { SignOutBody } from "./_sign-out";
import { SignOutStrongText } from "./_sign-out.strong";
import { SignOutText } from "./_sign-out.text";
import { parse } from "query-string";

const TIMEOUT = 60;

const bold = css`
  font-weight: 600;
`;

const SignOut = (props) => {
  const history = useHistory();
  const [timeRemaining, setTimeRemaining] = useState(TIMEOUT);

  const { configuration } = selectors;
  const searlUrl = useSelector(configuration.getSealUrl);
  const clerkTitle = useSelector(configuration.getClerkTitle);
  const isPlural = timeRemaining > 1 ? "s" : "";
  const redirectString = "Redirecting to Search in";
  const secondsString = `second${isPlural}...`;
  const { returnPath } = parse(props.location.search);

  useInterval(() => {
    const newTimeRemaining = timeRemaining - 1;

    if (newTimeRemaining <= 0) {
      history.push("/");
    } else {
      setTimeRemaining(newTimeRemaining);
    }
  }, 1000);

  if (returnPath) {
    return <Redirect to={returnPath} />;
  }

  return (
    <Container>
      <Helmet>
        <title>Sign Out</title>
      </Helmet>
      <A11yAnnouncement>Navigated to sign out page</A11yAnnouncement>
      <Header>
        {redirectString}
        &nbsp;
        <span css={bold}>{timeRemaining}</span>
        &nbsp;
        {secondsString}
      </Header>
      <SignOutBody>
        <img src={searlUrl} alt="county seal" />
        <SignOutStrongText>You are now signed out.</SignOutStrongText>
        <SignOutText>
          Thank you for visiting the {clerkTitle} website.
        </SignOutText>
        <SignOutText>
          You will need to sign in again to access your information.
        </SignOutText>
        <Link to="/" className="sign-out__return-to-search">
          Return To Search
        </Link>
        <Link to="/signin" className="sign-out__sign-in-link">
          Sign In To Your Account
        </Link>
      </SignOutBody>
    </Container>
  );
};

export default SignOut;
