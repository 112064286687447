import classnames from "classnames";
import PropTypes from "prop-types";
import { parse } from "query-string";
import assoc from "ramda/src/assoc";
import compose from "ramda/src/compose";
import map from "ramda/src/map";
import pick from "ramda/src/pick";
import prop from "ramda/src/prop";
import reduce from "ramda/src/reduce";
import toPairs from "ramda/src/toPairs";
import { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  A11yAnnouncement,
  ErrorBar,
  Form,
  ReturnButton,
} from "../../../components";
import { withKeys } from "../../../hoc";
import { actions, selectors } from "../../../store";
import { noop } from "../../../utils";
import Container from "../_loginRegisterFooter";
import getFormConfig from "./formConfig";

const FormRow = ({ children }) => (
  <div className="user-form__row">{children}</div>
);

const getFormProps = pick(["pricePerDocument"]);

export class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error,
    };
  }

  componentWillUnmount() {
    this.props.clearError();
  }

  render() {
    const {
      updateFormValue,
      updateFormError,
      formState,
      location,
      register,
      loading,
      registrationError,
    } = this.props;

    const formActions = {
      onChange: updateFormValue,
    };

    const formConfig = getFormConfig(getFormProps(this.props));
    const form = Form.buildForm(formConfig, formState, formActions);

    const handleSubmit = (event) => {
      event.preventDefault();

      if (Form.checkIfFormValid(formConfig, formState, updateFormError)) {
        const formValues = {
          ...map(prop("value"), formState),
        };

        formValues.accountType = "regular";
        formValues.company = "";

        return register(formValues);
      }
    };

    const submitButtonClass = classnames(
      "user-form__submit-button",
      "user-form__button"
    );

    const { returnPath } = parse(location.search);
    const signInPath = `/signin?returnPath=${encodeURIComponent(returnPath)}`;

    return (
      <div>
        <Helmet>
          <title>Register</title>
        </Helmet>
        <A11yAnnouncement>Navigated to register page</A11yAnnouncement>
        <ReturnButton />
        <form className="user-form" onSubmit={handleSubmit}>
          <div className="user-form__title-wrap">
            <h1 className="user-form__title">Register for an Account</h1>
          </div>
          <ErrorBar error={registrationError} />
          <div className="user-form__body">
            <FormRow>{form.firstName}</FormRow>
            <FormRow>{form.lastName}</FormRow>
            <FormRow>{form.email}</FormRow>
            <FormRow>{form.password}</FormRow>
            <div className="user-form__footer-wrap">
              <button type="submit" className={submitButtonClass}>
                {loading ? "Loading..." : "Create Account"}
              </button>
            </div>
          </div>
        </form>
        <Container>
          Already have an account?&nbsp;
          <Link to={signInPath}>Sign In</Link>
        </Container>
      </div>
    );
  }
}

View.displayName = "View";

View.propTypes = {
  updateFormValue: PropTypes.func.isRequired,
  updateFormError: PropTypes.func.isRequired,
  clearError: PropTypes.func,
  state: PropTypes.shape({
    email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
    password: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  register: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

View.defaultProps = {
  register: noop,
};

const mapStateToProps = (state) => ({
  loading: selectors.user.getLoading(state),
  registrationComplete: selectors.user.getRegistrationComplete(state),
  registrationError: selectors.user.getRegistrationError(state),
  pricePerDocument: selectors.configuration.getPricePerPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  register: (form) => dispatch(actions.user.register(form)),
  clearError: () => dispatch(actions.user.clearError()),
});

const getValuesFromConfig = compose(
  reduce((acc, [key, { value }]) => assoc(key, value, acc), {}),
  toPairs
);

const initializer = compose(
  (input) => Form.createInitialState(input, getValuesFromConfig(input)),
  getFormConfig,
  getFormProps
);
const enhance = compose(
  withRouter,
  withKeys,
  connect(mapStateToProps, mapDispatchToProps),
  Form.withFormStateInitialized("formState", initializer)
);

export default enhance(View);
