import { css } from "@emotion/react";
import { Form } from "@kofile/react-form";
import classnames from "classnames";
import PropTypes from "prop-types";
import { parse } from "query-string";
import { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import ErrorBar from "../../../components/ErrorBar";
import { PasswordInput } from "../../../components/Fields";
import { actions, selectors } from "../../../store";
import Container from "../_loginRegisterFooter";
import { FormFooter, FormRow } from "./components";
import config from "./formConfig";

const styles = {
  verifiedMsg: css`
    margin: 16px;
  `,
};

const invalidRedirects = ["/signin", "/register", "/signout"];

export class View extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = ({ isValid, formValues }) => {
      if (isValid) {
        this.props.signIn(formValues);
      }
    };

    this.onChange = () => this.props.loginError && this.props.clearError();
  }

  componentWillReceiveProps(props) {
    let { returnPath = "/" } = parse(props.location.search);
    const { workspaceID } = props;

    invalidRedirects.forEach((invalidRedirect) => {
      if (
        matchPath(returnPath, {
          path: invalidRedirect,
        })
      ) {
        returnPath = "/";
      }
    });

    if (props.loggedIn) {
      if (props.isModal) {
        props.close();
        props.actionOnSignIn();
      } else {
        props.history.push(decodeURIComponent(returnPath), { workspaceID });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearError();
  }

  render() {
    const {
      isLoading,
      loginError,
      isModal = false,
      close,
      location,
      verified,
    } = this.props;

    const { returnPath } = parse(location.search);
    const registerPath = `/register?returnPath=${encodeURIComponent(
      returnPath
    )}`;

    const submitButtonClass = classnames(
      "user-form__submit-button",
      "user-form__button"
    );

    const headerClass = classnames({
      "user-form__modal-title-wrap": isModal,
      "user-form__title-wrap": !isModal,
    });

    const phrase = "Don't have an account?";
    const registrationLink = (
      <Container>
        {phrase}&nbsp;
        <Link to={registerPath}>Register</Link>
      </Container>
    );

    return (
      <Fragment>
        <Helmet>
          <title>Sign In</title>
        </Helmet>
        <div className="user-form">
          <div className={headerClass}>
            <h1 className="user-form__title">Sign In</h1>
          </div>
          <ErrorBar error={loginError} />
          {verified && (
            <p className="success message" css={styles.verifiedMsg}>
              Your account has been verified.
            </p>
          )}
          <Form
            config={config}
            onChange={this.onChange}
            onSubmit={this.handleSubmit}
            method="post"
            render={(fields) => (
              <div className="user-form__body">
                <FormRow>
                  <input
                    type="email"
                    placeholder="Email"
                    data-testid="loginEmail"
                    required
                    {...fields.email.inputProps}
                    id="email"
                  />
                </FormRow>
                <FormRow>
                  <PasswordInput
                    placeholder="Password"
                    showPasswordOption
                    required
                    {...fields.password.inputProps}
                    id="password"
                  />
                </FormRow>
                <FormFooter
                  isModal={isModal}
                  fields={fields}
                  submitButtonClass={submitButtonClass}
                  isLoading={isLoading}
                  close={close}
                />
              </div>
            )}
          />
        </div>
        {isModal ? null : registrationLink}
      </Fragment>
    );
  }
}

View.displayName = "View";

View.propTypes = {
  signIn: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  workspaceID: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    isLoading: user.loading,
    loginError: user.loginError,
    loggedIn: user.loggedIn,
    workspaceID: selectors.workspaces.getActiveWorkspaceID(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch(actions.user.clearError()),
  signIn: ({ email, password }) =>
    dispatch(actions.user.signIn({ email, password })),
});

export const EnhancedView = withRouter(View);

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedView);
