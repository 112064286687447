import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

const FormRow = ({ children }) => (
  <div className="user-form__row">{children}</div>
);

const FormFooter = (props) => {
  const { isModal = false, isLoading, close, submitButtonClass } = props;

  const modalView = (
    <div className="user-form__modal-footer">
      <div className="user-form__row-text">
        <Link to="/forgotpassword">Forgot Password?</Link>
      </div>
      <div className="user-form__modal-buttons">
        <button
          className="user-form__modal-cancel"
          type="button"
          onClick={close}
        >
          Cancel
        </button>
        <button
          className="user-form__modal-submit"
          type="submit"
          data-testid="loginButton"
        >
          {isLoading ? "Loading..." : "Sign In"}
        </button>
      </div>
    </div>
  );

  const pageView = (
    <div>
      <FormRow>
        <div className="user-form__separate-children">
          <div className="user-form__row-text">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
        </div>
      </FormRow>
      <div className="user-form__footer-wrap">
        <button
          className={submitButtonClass}
          type="submit"
          data-testid="loginButton"
        >
          {isLoading ? "Loading..." : "Sign In"}
        </button>
      </div>
    </div>
  );

  return isModal ? modalView : pageView;
};

FormFooter.displayName = "FormFooter";
FormFooter.propTypes = {
  isModal: PropTypes.bool,
  fields: PropTypes.shape({
    rememberMe: PropTypes.shape({}),
  }),
  isLoading: PropTypes.bool,
};

export default withRouter(FormFooter);
