import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "../../../store";

export const SearchMasthead = ({ sealUrl, countyName, searchTitle }) => {
  if (!searchTitle) {
    return null;
  }

  return (
    <div className="search-masthead">
      <img
        className="search-masthead__seal"
        alt={`county seal for ${countyName}`}
        src={sealUrl}
      />
      <div className="search-masthead__seal-title">{searchTitle}</div>
    </div>
  );
};

SearchMasthead.displayName = "SearchMasthead";

SearchMasthead.propTypes = {
  sealUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  countyName: selectors.configuration.getCountyName(state),
  sealUrl: selectors.configuration.getSealUrl(state),
  searchTitle: selectors.configuration.getSearchTitle(state),
});

export default connect(mapStateToProps)(SearchMasthead);
