import { css } from "@emotion/react";

const style = (customStyle) => css`
  margin-top: 30px;
  color: #596e93;
  text-align: center;

  h1 {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: left;
  }

  h3 {
    text-align: left;
    font-weight: 600;

    & span {
      font-weight: 400;

      & button {
        color: #006dd5;
        background-color: var(--color-neutral-lightest);
      }
    }
  }

  ol {
    list-style-type: decimal;
    text-align: left;

    & li {
      line-height: 1.5;
      margin-left: 30px;

      &:last-of-type {
        padding-bottom: 20px;
      }
    }
  }

  ul {
    margin: 0;
    list-style-type: disc;

    & > li {
      margin-left: 30px;
    }
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
  }

  button {
    margin: 5px;
  }

  strong {
    font-weight: 600;
  }

  ${customStyle}
`;

export const ConfiguredHTMLContent = ({ html, customStyle = "" }) => (
  <div css={style(customStyle)} dangerouslySetInnerHTML={{ __html: html }} />
);
