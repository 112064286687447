import PropTypes from "prop-types";

const FormRow = ({ children }) => (
  <div className="user-form__row">{children}</div>
);

FormRow.displayName = "FormRow";
FormRow.propTypes = {
  children: PropTypes.node,
};

export default FormRow;
