import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stripe } from "../../../components";
import { withSSRRenderGuard } from "../../../hoc";
import { actions, selectors } from "../../../store";
import { stringify } from "query-string";
import View from "./View";

const Register = () => {
  const dispatch = useDispatch();
  const email = useSelector(selectors.user.getUserEmail);

  const registrationComplete = useSelector(
    selectors.user.getRegistrationComplete
  );

  if (registrationComplete) {
    const qs = stringify({ email });
    const returnPath = "/check-registration-email?" + qs;

    window.location = `/signout?returnPath=${returnPath}`;

    return null;
  } else {
    return (
      <Stripe>
        <View />
      </Stripe>
    );
  }
};

export default withSSRRenderGuard(Register);
