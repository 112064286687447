import { css } from "@emotion/react";
import { isFuture, parseISO } from "date-fns";
import prop from "ramda/src/prop";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import subscriptionsContext from "../../App/screens/Account/SettingsView/views/subscriptions-context";
import { Button } from "./_activate.button";
import { ConfiguredHTMLContent } from "./configured-content";

const style = css`
  display: flex;
  flex-direction: column;

  button {
    align-self: center;
    margin-top: 10px;
  }
`;

export const ApplicationOverview = ({ next, data }) => {
  const history = useHistory();
  const { subscriptions } = useContext(subscriptionsContext);

  const hasValidSubs =
    Array.isArray(subscriptions) &&
    subscriptions.some(({ status, endDate }) => {
      const endsInFuture = isFuture(parseISO(endDate));
      return status === "active" || (status === "canceled" && endsInFuture);
    });

  if (hasValidSubs) history.push("/account/subscription");

  return (
    <section css={style}>
      <ConfiguredHTMLContent html={prop("html", data)} />
      <Button onClick={next}>Become A Subscriber</Button>
    </section>
  );
};
