import styled from "@emotion/styled";

const LoginRegisterFooter = styled.p`
  color: var(--color-neutral-700);
  text-align: center;
  font-size: 13px;
  padding: 24px;

  & > a {
    color: var(--color-primary-600);
    text-decoration: underline;
  }
`;

export default LoginRegisterFooter;
